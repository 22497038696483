:root {
  --font-family-base: 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  --color-accent: #ff4100;
  --font-size-base: 14px;
  --line-height-base: 1.6;
}

.category-description {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

.category-description p {
  margin-bottom: 1rem;
  display: inline;
}

.category-description p:not(:last-of-type) {
  display: block;
}

.category-description h2,
.category-description h3 {
  font-weight: 700;
  margin: 1rem 0 0.5rem 0;
}

.category-description h2 {
  font-size: 16px;
}
.category-description h3 {
  font-size: 15px;
}

.category-description ul,
.category-description ol {
  list-style-position: outside;
  padding-left: 2rem;
  margin: 1rem 0;
}

.category-description ul {
  list-style-type: disc;
}
.category-description ol {
  list-style-type: decimal;
}

.category-description li {
  margin-bottom: 0.75rem;
  line-height: 1.6;
  font-size: 14px;
  display: list-item;
  text-align: -webkit-match-parent;
}

.category-description a,
button.text-secondary,
a[data-js='category-description-show-more'] {
  color: var(--color-accent);
}

button.text-secondary,
a[data-js='category-description-show-more'] {
  margin-left: -0.1em;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  vertical-align: baseline;
}
